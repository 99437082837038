.board {
  $selector: &;

  background-image: url('/assests/img/xv.png');
  border-radius: var(--board-radius);
  box-shadow: #333 2px 2px 5px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  height: var(--board-size);
  margin: 0 auto;
  padding: var(--tile-gap);
  position: relative;
  width: var(--board-size);

  &:not(#{$selector}_done) {
    grid-gap: var(--tile-gap);

    #{$selector}__tile {
      border-radius: var(--tile-border-radius);
      border-style: solid;
      box-shadow: #000 2px 2px 2px;
      cursor: pointer;

      &:hover {
        box-shadow: #000 8px 8px 10px;
        left: -4px;
        top: -4px;
      }

      @for $i from 0 through 14 {
        &_#{$i + 1}:after {
          content: "#{$i + 1}";
        }
      }
    }
  }

  &__tile {
    border-color: var(--tile-border-color);
    border-width: 1px;
    overflow: hidden;
    position: relative;

    &:before {
      content: ' ';
      height: var(--board-size);
      position: absolute;
      width: var(--board-size);
    }
    &:after {
      color: var(--tile-number-color);
      left: calc(var(--board-size) / 50);
      position: absolute;
      text-shadow: black 2px 2px 2px;
      top: calc(var(--board-size) / 50);
    }

    @for $i from 0 through 15 {
      &_#{$i + 1} {
        &:before {
          left: ($i % 4 * 100% * -1);
          top: (floor($i / 4) * 100% * -1);
        }

        @if ($i % 4 == 0) {
          border-left: {
            color: var(--tile-border-color);
            style: solid;
          }
        }

        @if ($i % 4 == 3) {
          border-right: {
            color: var(--tile-border-color);
            style: solid;
          }
        }

        @if (floor($i / 4) == 0) {
          border-top: {
            color: var(--tile-border-color);
            style: solid;
          }
        }

        @if (floor($i / 4) == 3) {
          border-bottom: {
            color: var(--tile-border-color);
            style: solid;
          }
        }

      }
    }

    &_1 {
      border-top-left-radius: var(--tile-border-radius);
    }
    &_4 {
      border-top-right-radius: var(--tile-border-radius);
    }
    &_13 {
      border-bottom-left-radius: var(--tile-border-radius);
    }
    &_16 {
      border-bottom-right-radius: var(--tile-border-radius);
    }
  }

  @for $i from 1 through 15 {
    &[data-level="#{$i}"] {
      #{$selector}__tile:before {
        @if ($i < 10) {
          background-image: url('/assests/img/puzzles/00#{$i}.png');
        }
        @else if($i < 100) {
          background-image: url('/assests/img/puzzles/0#{$i}.png');
        }
        @else if($i < 1000) {
          background-image: url('/assests/img/puzzles/#{$i}.png');
        }
      }
    }
  }

  &__info {
    background-color: rgba(0, 0, 0, 0.9);
    border-bottom-left-radius: var(--tile-border-radius);
    border-bottom-right-radius: var(--tile-border-radius);
    border-color: var(--tile-border-color);
    border-style: solid;
    border-width: 1px;
    bottom: var(--tile-gap);
    color: var(--tile-number-color);
    left: var(--tile-gap);
    padding: var(--tile-gap);
    position: absolute;
    right: var(--tile-gap);
  }
}
