.help {
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: var(--board-radius);
  bottom: calc(-1 * var(--overflow));
  color: #cccccc;
  cursor: pointer;
  display: flex;
  left: 50%;
  padding: var(--tile-gap);
  position: absolute;
  top: calc(-1 * var(--overflow));
  transform: translate(-50%);
  width: calc(var(--board-size) + 2 * var(--overflow));
}

.rules {
  list-style: none;

  &__rule {
    position: relative;
    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &:before {
      display: block;
      position: absolute;
      left: -22px;
    }

    &_aim:before {
      content: '🎯';
    }
    &_move:before {
      content: '👆';
    }
    &_undo:before {
      content: '⏮';
    }
    &_shuffle:before {
      content: '🔀';
    }
  }
}

.help > .container {
  margin: auto;
  line-height: 1.5;
}
